import './arrowUp.css';


const ArrowUp = () => (
    <a href="#hero">
      <img className='scroll-to-top' src="/arrow-up.png" alt="Kitty in a jar" />
    </a>
  );

export default ArrowUp;
  