import "./about.css";

const About = () => {
  return (
    <section id="about" className="about-section">
      <div class="about-bubbles">
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
        <div class="about-bubble"></div>
      </div>
      <div className="about-wrapper">
        {/* <div className="kitty-image">
          <img
            className="kitty-img"
            src="/catch-bubble.gif"
            alt="Kitty in a jar"
          />
        </div> */}
        <div className="about-content">
          <h2>
            About <dev className="title"> bubbleKitty</dev>
          </h2>
          <div className="cards">
            
            <div className="card">
              <h3>Community Driven</h3>
              <p>
                bubbleKitty is a community-driven meme coin project, built on
                the principles of transparency, fairness, and fun. We believe in
                empowering our community to shape the future of bubbleKitty.
              </p>
            </div>

            <div className="card">
              <h3>Unique Gameplay</h3>
              <p>
                Our Telegram mini-app features a unique and engaging gameplay
                experience. Join the fun and catch those bubbles to earn
                rewards!
              </p>
            </div>

            <div className="card">
              <h3>Exciting Rewards</h3>
              <p>
                Airdrops and other exciting rewards are planned for our
                community members. Be a part of the about-bubbleKitty journey
                and reap the benefits.
              </p>
            </div>
            <div className="kitty-image">
              <img
                className="kitty-img"
                src="/catch-bubble.gif"
                alt="Kitty in a jar"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="game-content">
        <h1>
          The Telegram Game: <span className="title">Catch the Bubble</span>
        </h1>
        <div className="steps">
          <div className="step">
            <div className="step-text">
              <p className="step-title">Start Catching</p>
              <p>
                Begin your journey in the underwater world of BubbleKitty by
                tapping the orange cat.
              </p>
            </div>
            <div className="step-number-container">
              <span className="step-number">1</span>
            </div>
          </div>
          <div className="step">
            <div className="step-text">
              <p className="step-title">Accumulate Points</p>
              <p>
                Each successful tap earns you points and brings you closer to
                the surface.
              </p>
            </div>
            <div className="step-number-container">
              <span className="step-number">2</span>
            </div>
          </div>
          <div className="step">
            <div className="step-text">
              <p className="step-title">Reach the Surface</p>
              <p>
                After 100 consecutive taps, the cat will reach the surface, and
                your rewards will be tripled.
              </p>
            </div>
            <div className="step-number-container">
              <span className="step-number">3</span>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default About;
