import "./roadmap.css";

const Roadmap = () => (
  <section id="roadmap" className="roadmap-section">
    <img src="/black-cat.png" className="cat-img" alt="cat-black" />
    <div class="about-bubbles">
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
      <div class="about-bubble"></div>
    </div>
    <div className="roadmap-wrapper">
      <h1 className="roadmap-title">
        Roadmap: Bringing <span className="title">BubbleKitty</span> to Life
      </h1>
      <div className="roadmap-container">
        {/* <div className="roadmap-image">
                    <img src="/fish.png" alt="BubbleKitty" />
                </div> */}
        <div className="roadmap-timeline">
          <div className="roadmap-item">
            <div className="roadmap-number">1</div>
            <div className="roadmap-details">
              <h2>Phase 1: Community Building</h2>
              <p>
                Building a strong community is our top priority. We will be
                hosting contests, giveaways, and community events to engage our
                members.
              </p>
            </div>
          </div>
          <div className="roadmap-item">
            <div className="roadmap-number">2</div>
            <div className="roadmap-details">
              <h2>Phase 2: Token Launch</h2>
              <p>
                The official launch of the BubbleKitty token will be a momentous
                occasion, marking the beginning of our exciting journey.
              </p>
            </div>
          </div>
          <div className="roadmap-item">
            <div className="roadmap-number">3</div>
            <div className="roadmap-details">
              <h2>Phase 3: Exchange Listing</h2>
              <p>
                Listing on major cryptocurrency exchanges will provide greater
                liquidity and accessibility for our community.
              </p>
            </div>
          </div>
          <div className="roadmap-item">
            <div className="roadmap-number">4</div>
            <div className="roadmap-details">
              <h2>Phase 4: Decentralized Applications (DApps)</h2>
              <p>
                We will be exploring the development of decentralized
                applications, such as games and utilities, to enhance the
                BubbleKitty ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Roadmap;
