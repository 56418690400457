import React, { useEffect, useState } from 'react';
import './menu.css';

const Menu = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 480);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header id='head' className={`menu fixed-header ${scrolled ? 'scrolled' : ''}`}>
            <nav className="nav">
                <ul className="nav-list">
                    <li><a href="#hero" className=""><img src="/CatHead.png" alt="Logo" className="menu-logo" /></a></li>
                    <li><a href="#about" className="nav-link">About</a></li>
                    <li><a href="#telegram-game" className="nav-link">Telegram Game</a></li>
                    <li><a href="#roadmap" className="nav-link">Roadmap</a></li>
                    {!isSmallScreen && (
                        <li>
                            <a href="https://t.me/bubblekitty_chat" target="_blank" className="nav-link join-us">
                                Join Us <img src="/tg-small.png" alt="Logo" className="tg-logo" />
                            </a>
                        </li>
                    )}
                    {isSmallScreen && (
                        <li>
                            <a href="https://t.me/bubblekitty_chat" target="_blank" className="nav-link">
                                <img src="/tg-small.png" alt="Telegram" className="tg-logo" />
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default Menu;
