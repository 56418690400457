import React from 'react';
import Menu from './components/menu/Menu'
import Hero from './components/hero/Hero'
import About from './components/about/About'
import TelegramGame from './components/tgGame/TelegramGame'
import Roadmap from './components/roadmap/Roadmap'
import Footer from './components/footer/Footer'
import ArrowUp from './components/arrow/ArrowUp'
import './App.css';

function App() {
  return (
    <div className="App">
      <Menu />
      <Hero />
      <About />
      <TelegramGame />
      <Roadmap />
      <Footer />
      <ArrowUp />

      {/* <Tokenomics /> */}
      {/* <AirdropSection /> */}
      {/* <SocialLinks /> */}
      {/* <Community /> */}
    </div>
  );
}

// const ArrowUp = () => (
//   <a href="#head">
//     <img className='scroll-to-top' src="/arrow-up.png" alt="Kitty in a jar" />
//   </a>
// );

// const Header = () => (
//   <header id='head' className="menu">
//     <nav className="nav">
//       <ul className="nav-list">
//         <li><a href="#about" className="nav-link">About</a></li>
//         <li><a href="#telegram-game" className="nav-link">Telegram Game</a></li>
//         <li><a href="#roadmap" className="nav-link">Roadmap</a></li>
//         <li><a href="#footer" className="nav-link join-us">Join Us</a></li>
//       </ul>
//     </nav>
//   </header>
// );

// const Hero = () => (
//   <section className="header">
//     <div className="header-content">
//       <h1>BubbleKitty: The Meme Coin with a Splash</h1>
//       <p>Dive into the world of BubbleKitty, where you can earn rewards with the cutest orange cat in the memeverse.</p>
//       <a href="https://t.me/Bubble_Kitty_Game_bot" className="btn">Join Telegram</a>
//     </div>

//     <div className="social-links-grid">
//       <div className="social-link">
//         <div className="icon telegram-icon"></div>
//       </div>
//       <div className="social-link">
//         <div className="icon twitter-icon"></div>
//       </div>
//       <div className="social-link">
//         <div className="icon discord-icon"></div>
//       </div>
//       <div className="social-link">
//         <div className="icon instagram-icon"></div>
//       </div>
//     </div>
//   </section>
// );

// const About = () => {
//   return (
//     <section id="about" className="about-section">
//       <div className='about-wrapper'>
//         <div className="about-content">
//           <h2>About BubbleKitty</h2>
//           <div className="cards">
//             <div className="card">
//               <h3>Community Driven</h3>
//               <p>
//                 BubbleKitty is a community-driven meme coin project, built on the principles
//                 of transparency, fairness, and fun. We believe in empowering our community
//                 to shape the future of BubbleKitty.
//               </p>
//             </div>

//             <div className="card">
//               <h3>Unique Gameplay</h3>
//               <p>
//                 Our Telegram mini-app features a unique and engaging gameplay experience.
//                 Join the fun and catch those bubbles to earn rewards!
//               </p>
//             </div>

//             <div className="card">
//               <h3>Exciting Rewards</h3>
//               <p>
//                 Airdrops and other exciting rewards are planned for our community members.
//                 Be a part of the BubbleKitty journey and reap the benefits.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="kitty-image">
//           <img src="/fish.png" alt="Kitty in a jar" />
//         </div>
//       </div>
//     </section>
//   );
// };

// const TelegramGame = () => (
//   <section id='telegram-game' className="telegram-game">
//     <div className='telegram-game-wrapper'>
//       <div className="game-content">
//         <h1>The Telegram Game: Catch the Bubble</h1>
//         <div className="steps">
//           <div className="step">
//             <div className="step-number-container">
//               <span className="step-number">1</span>
//             </div>
//             <div className="step-text">
//               <p className="step-title">Start Catching</p>
//               <p>Begin your journey in the underwater world of BubbleKitty by tapping the orange cat.</p>
//             </div>
//           </div>
//           <div className="step">
//             <div className="step-number-container">
//               <span className="step-number">2</span>
//             </div>
//             <div className="step-text">
//               <p className="step-title">Accumulate Points</p>
//               <p>Each successful tap earns you points and brings you closer to the surface.</p>
//             </div>
//           </div>
//           <div className="step">
//             <div className="step-number-container">
//               <span className="step-number">3</span>
//             </div>
//             <div className="step-text">
//               <p className="step-title">Reach the Surface</p>
//               <p>After 100 consecutive taps, the cat will reach the surface, and your rewards will be tripled.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bubble-background">
//         {/* Bubble background image */}
//       </div>
//     </div>
//   </section>
// );

const AirdropSection = () => (
  <section className="airdrop">
    <div className='airdrop-section'>
      <h1>Earn Rewards with the Airdrop</h1>
      <p>
        To participate in the airdrop, first join the Telegram Game by clicking the button below.
        Then, you will need to add your Solana address in the game. That’s it!
        You'll be automatically entered into the airdrop.
      </p>
      <a href="https://t.me/bubblekitty_chat" target="_blank" rel="noopener noreferrer">
        <button className="join-game-button">Join Telegram Game</button>
      </a>
      <ul className="rewards-list">
        <li>Points for playing the Telegram Game</li>
        <li>Rewards for inviting friends</li>
        <li>Early adoption bonuses</li>
      </ul>
    </div>
  </section>
)

const SocialLinks = () => (
  <div className="social-links-section">
    <h1>Connect with Us: Social Links</h1>
    <div className="social-links-grid">
      <div className="social-link">
        <div className="icon telegram-icon"></div>
        <h2>Telegram</h2>
        <p>Join our Telegram group to stay updated and interact with the BubbleKitty community.</p>
      </div>
      <div className="social-link">
        <div className="icon twitter-icon"></div>
        <h2>Twitter</h2>
        <p>Follow us on Twitter for the latest news, announcements, and memes.</p>
      </div>
      <div className="social-link">
        <div className="icon discord-icon"></div>
        <h2>Discord</h2>
        <p>Join our Discord server for lively discussions, community events, and even more fun.</p>
      </div>
      <div className="social-link">
        <div className="icon instagram-icon"></div>
        <h2>Instagram</h2>
        <p>Connect with other BubbleKitty enthusiasts on our dedicated subreddit.</p>
      </div>
    </div>
  </div>
);

// const Roadmap = () => (
//   <section id='roadmap' className="roadmap-section">
//     <div className='roadmap-wrapper'>
//       <h1>Roadmap: Bringing BubbleKitty to Life</h1>
//       <div className="roadmap-container">
//         <div className="roadmap-image">
//           <img src="/fish.png" alt="BubbleKitty" />
//         </div>
//         <div className="roadmap-timeline">
//           <div className="roadmap-item">
//             <div className="roadmap-number">1</div>
//             <div className="roadmap-details">
//               <h2>Phase 1: Community Building</h2>
//               <p>
//                 Building a strong community is our top priority. We will be hosting contests, giveaways, and community events to engage our members.
//               </p>
//             </div>
//           </div>
//           <div className="roadmap-item">
//             <div className="roadmap-number">2</div>
//             <div className="roadmap-details">
//               <h2>Phase 2: Token Launch</h2>
//               <p>
//                 The official launch of the BubbleKitty token will be a momentous occasion, marking the beginning of our exciting journey.
//               </p>
//             </div>
//           </div>
//           <div className="roadmap-item">
//             <div className="roadmap-number">3</div>
//             <div className="roadmap-details">
//               <h2>Phase 3: Exchange Listing</h2>
//               <p>
//                 Listing on major cryptocurrency exchanges will provide greater liquidity and accessibility for our community.
//               </p>
//             </div>
//           </div>
//           <div className="roadmap-item">
//             <div className="roadmap-number">4</div>
//             <div className="roadmap-details">
//               <h2>Phase 4: Decentralized Applications (DApps)</h2>
//               <p>
//                 We will be exploring the development of decentralized applications, such as games and utilities, to enhance the BubbleKitty ecosystem.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
// );

const Community = () => (
  <section class="community-section">
    <div class="community-container">
      <div class="image-container">
        <img src="/meow.png" alt="Meow Image" class="meow-image" />
      </div>
      <div class="content-container">
        <h1>Join the <span>BubbleKitty</span> Community</h1>
        <p>
          Get ready to dive headfirst into the BubbleKitty family! It's time to unleash your inner cat and join a community that's
          overflowing with fun and exciting opportunities. We're creating a world where everyone can be a part of the BubbleKitty magic,
          so don't wait another second - jump in and get ready to make a splash!
        </p>
        <button class="join-button">Join the Fun!</button>
      </div>
    </div>
  </section>
);

// const Footer = () => (
//   <footer className="footer">
//     <div className="footer-container">
//       <div className="footer-section company-info">
//         <img src="/CatHead.png" alt="Logo" className="footer-logo" />
//         <p>
//           BubbleKitty: The cutest meme coin in the cryptosphere. Earn rewards, join the
//           community, and have fun with the most playful orange cat!
//         </p>
//       </div>

//       <div className="footer-section links">
//         <h3>Quick Links</h3>
//         <ul>
//           <li><a href="#about">About Us</a></li>
//           <li><a href="#telegram-game">Telegram Game</a></li>
//           <li><a href="#roadmap">Roadmap</a></li>
//           <li><a href="#community">Community</a></li>
//           <li><a href="#faq">FAQ</a></li>
//         </ul>
//       </div>

//       <div className="footer-section social-media">
//         <h3>Follow Us</h3>
//         <ul>
//           <li><a href="https://telegram.org">Telegram</a></li>
//           <li><a href="https://twitter.com">Twitter</a></li>
//           <li><a href="https://discord.com">Discord</a></li>
//           <li><a href="https://instagram.com">Instagram</a></li>
//         </ul>
//       </div>

//       <div className="footer-section legal">
//         <h3>Legal</h3>
//         <ul>
//           <li><a href="#terms">Terms & Conditions</a></li>
//           <li><a href="#privacy">Privacy Policy</a></li>
//           <li><a href="#disclaimer">Disclaimer</a></li>
//           <li><a href="#support">Support</a></li>
//         </ul>
//       </div>
//     </div>

//     <div className="footer-bottom">
//       <p>&copy; 2024 BubbleKitty. All Rights Reserved.</p>
//     </div>
//   </footer>
// );

export default App;
