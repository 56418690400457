import "./tgGame.css";

const TelegramGame = () => (
  <section id="telegram-game" className="telegram-game">
    <div className="telegram-game-wrapper">
      <img src="/cat-in-jar.png" alt="cat" className="jar-cat" />  
      <div className="game-content">
        <h1>
          The Telegram Game: <span className="title">Catch the Bubble</span>
        </h1>
        <div className="steps">
          <div className="step">
            <div className="step-text">
              <p className="step-title">Start Catching</p>
              <p>
                Begin your journey in the underwater world of BubbleKitty by
                tapping the orange cat.
              </p>
            </div>
            <div className="step-number-container">
              <span className="step-number">1</span>
            </div>
          </div>
          <div className="step">
            <div className="step-text">
              <p className="step-title">Accumulate Points</p>
              <p>
                Each successful tap earns you points and brings you closer to
                the surface.
              </p>
            </div>
            <div className="step-number-container">
              <span className="step-number">2</span>
            </div>
          </div>
          <div className="step">
            <div className="step-text">
              <p className="step-title">Reach the Surface</p>
              <p>
                After 100 consecutive taps, the cat will reach the surface, and
                your rewards will be tripled.
              </p>
            </div>
            
          </div>
        </div>
      </div>
      {/* <div className="bubble-background">Bubble background image</div>   */}
    </div>
  </section>
);

export default TelegramGame;
