import "./hero.css";

const Hero = () => (
  <section id="hero" className="header">
    <div className="bubble">
      <div class="wrapper">
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
        <div>
          <span class="dot"></span>
        </div>
      </div>
      <img src="/CatHead.png" alt="cat" className="bubble-cat" />
    </div>
    <div className="header-content">
      <h1>
        <span className="title">BubbleKitty:</span> The Meme Coin with a Splash
      </h1>
      <p>
        Dive into the world of BubbleKitty, where you can earn rewards with the
        cutest orange cat in the memeverse.
      </p>
      <a href="https://t.me/Bubble_Kitty_Game_bot" className="btn">
        Join Telegram
      </a>
    </div>

    <div className="social-links-grid">
      <div className="social-link">
        <a
          href="https://t.me/bubblekitty_news"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon telegram-icon"></div>
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://twitter.com/BubbleKittyCoin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon twitter-icon"></div>
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://www.tiktok.com/@bubblekitty_coin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon tiktok-icon"></div>
        </a>
      </div>
      <div className="social-link">
        <a
          href="https://www.instagram.com/bubble_kitty_coin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon instagram-icon"></div>
        </a>
      </div>
    </div>
  </section>
);

export default Hero;
