import './footer.css';

const Footer = () => (
    <footer className="footer">
        <div className="footer-container">
            <div className="footer-section company-info">
                <img src="/CatHead.png" alt="Logo" className="footer-logo" />
                <p>
                    BubbleKitty: The cutest meme coin in the cryptosphere. Earn rewards, join the
                    community, and have fun with the most playful orange cat!
                </p>
            </div>

            <div className="footer-section links">
                <h3>Quick Links</h3>
                <ul>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#telegram-game">Telegram Game</a></li>
                    <li><a href="#roadmap">Roadmap</a></li>
                    <li><a href="#community">Community</a></li>
                    <li><a href="#faq">FAQ</a></li>
                </ul>
            </div>

            <div className="footer-section social-media">
                <h3>Follow Us</h3>
                <ul>
                    <li><a href="https://t.me/bubblekitty_news">Telegram news</a></li>
                    <li><a href="https://t.me/bubblekitty_chat">Telegram comminuty</a></li>
                    <li><a href="https://twitter.com/BubbleKittyCoin">Twitter</a></li>
                    <li><a href="https://www.tiktok.com/@bubblekitty_coin">Tiktok</a></li>
                    <li><a href="https://www.instagram.com/bubble_kitty_coin">Instagram</a></li>
                </ul>
            </div>

            <div className="footer-section legal">
                <h3>Legal</h3>
                <ul>
                    <li><a href="#terms">Terms & Conditions</a></li>
                    <li><a href="#privacy">Privacy Policy</a></li>
                    <li><a href="#disclaimer">Disclaimer</a></li>
                    <li><a href="#support">Support</a></li>
                </ul>
            </div>
        </div>

        <div className="footer-bottom">
            <p>&copy; 2024 BubbleKitty. All Rights Reserved.</p>
        </div>
    </footer>
);

export default Footer;